import de from './locale/labels-de.json'
import en from './locale/labels-en.json'

export default defineI18nConfig(() => ({
  legacy: false,
  messages: {
    de,
    en,
  },
}))
